// Navbar.tsx
import React, { useState } from "react";
import {
  Box,
  Center,
  TabList,
  Tabs,
  Image,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import ScrollComponent from "./base/ScrollComponent";
import logo from "../assets/images/logo.png";

const Navbar: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = [
    {
      to: "home",
      label: "Home",
      icon: <Image src={logo} boxSize="1.2em" alt="Home Logo" />,
    },
    { to: "about", label: "About" },
    { to: "experience", label: "Experience" },
    { to: "skills", label: "Skills" },
    { to: "things", label: "Things" },
    { to: "contact", label: "Contact" },
  ];

  const handleMenuItemClick = (index: number, to: string) => {
    setActiveIndex(index);
    // Scroll to the section
    document.getElementById(to)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Center
        position="sticky"
        top={3}
        zIndex={100}
        paddingBottom="50px"
        display={{ base: "none", md: "flex" }}
      >
        <Tabs
          variant="soft-rounded"
          colorScheme="orange"
          size="lg"
          index={activeIndex}
          onChange={(index) => setActiveIndex(index)}
        >
          <TabList flexWrap="wrap" gap={10}>
            {tabs.map((tab, index) => (
              <ScrollComponent
                key={tab.to}
                to={tab.to}
                label={tab.label}
                icon={tab.icon}
                index={index}
                setActiveIndex={setActiveIndex}
              />
            ))}
          </TabList>
        </Tabs>
      </Center>

      <Box
        display={{ base: "flex", md: "none" }}
        position="relative"
        zIndex={100}
        p={4}
        justifyContent="left"
      >
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HamburgerIcon />}
            variant="outline"
            aria-label="Open Menu"
          />
          <MenuList minWidth="auto" left="50%" transform="translateX(-50%)">
            {tabs.map((tab, index) => (
              <MenuItem
                key={tab.to}
                onClick={() => handleMenuItemClick(index, tab.to)}
                textAlign="center"
                color="#4f772d"
              >
                {tab.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </>
  );
};

export default Navbar;
