import React from "react";
import {
  Box,
  SimpleGrid,
  Container,
  Heading,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import ContentCard from "./base/ContentCard";
import { items } from "../constants/things";

const headingMarginTop = { base: 10, md: 50 };

const Things: React.FC = () => {
  return (
    <Box
      id="things"
      minHeight="100vh"
      bgGradient="linear(to top, seashell, white)"
      pt={headingMarginTop}
    >
      <Container maxW="container.xl" px={5}>
        <Heading
          fontSize={{ base: "4xl", md: "6xl" }}
          textAlign="center"
          mt={headingMarginTop}
          mb={1}
        >
          Things
        </Heading>
        <Text
          textAlign="center"
          maxW="600px"
          mx="auto"
          mb={10}
          color={useColorModeValue("gray.600", "gray.300")}
          fontSize={{ base: "md", md: "lg" }}
          paddingTop="50px"
        >
          A small collection of things that I like!
        </Text>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3 }}
          spacing={{ base: 5, md: 8 }}
          p={{ base: 5, md: 10 }}
        >
          {items.map((item, index) => (
            <ContentCard
              key={index}
              imageSrc={item.imageSrc}
              title={item.title}
              link={item.link}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Things;
