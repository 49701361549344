import React from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  VStack,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Text,
  HStack,
  Container,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { skillsData } from "../constants/constants";

const Skills: React.FC = () => {
  const columns = { base: 1, sm: 2, md: 3, lg: 4, xl: 5 };

  return (
    <Box
      id="skills"
      py={10}
      bgGradient="linear(to top, white, seashell)"
      minHeight="100vh"
    >
      <Container maxW="container.xl" px={{ base: 5, md: 10 }}>
        <Heading
          fontSize={{ base: "4xl", md: "6xl" }}
          textAlign="center"
          mt={25}
          mb={4}
        >
          Skills
        </Heading>
        <Text
          textAlign="center"
          maxW="600px"
          mx="auto"
          mb={4}
          color={useColorModeValue("gray.600", "gray.300")}
          fontSize={{ base: "md", md: "lg", lg: "xl" }}
          pt={{ base: 5, md: 10 }}
        >
          I started out as a backend engineer and developed a love for all
          things backend. That being said, over the years {"I've"} developed my
          frontend skills and am now comfortable with it.
        </Text>

        <Text
          textAlign="center"
          maxW="600px"
          mx="auto"
          mb={10}
          color={useColorModeValue("gray.600", "gray.300")}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
          pt={{ base: 2, md: 5 }}
        >
          Working in the disability sector, {"I've"} also learned how important
          accessible design is. I also have experience with security and load
          testing.
        </Text>

        {/* Skills Grid */}
        <SimpleGrid
          columns={columns}
          spacing={{ base: 5, md: 8 }}
          pt={{ base: 5, md: 10 }}
        >
          {Object.keys(skillsData).map((key) => {
            const category = skillsData[key];
            return (
              <VStack key={key} align="start">
                {/* Category Heading */}
                <Heading as="h3" size="md" mb={4} color="teal.500">
                  {category.category}
                </Heading>

                {/* List of Skills */}
                <List spacing={2}>
                  {category.skills.map((skill: any) => (
                    <ListItem
                      key={skill.name}
                      _hover={{
                        // bg: useColorModeValue("gray.200", "gray.600"),
                        borderRadius: "md",
                      }}
                      p={2}
                    >
                      <HStack align="start">
                        {/* Skill Icon */}
                        {skill.icon ? (
                          <ListIcon as={skill.icon} color="teal.400" />
                        ) : (
                          <ListIcon as={CheckCircleIcon} color="teal.400" />
                        )}

                        {/* Skill Name and Level */}
                        <VStack align="start" spacing={0}>
                          <Text fontWeight="medium">{skill.name}</Text>
                          {skill.level && (
                            <Text fontSize="sm" color="gray.500">
                              {skill.level}
                            </Text>
                          )}
                        </VStack>
                      </HStack>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            );
          })}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Skills;
