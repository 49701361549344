import React from "react";
import CompanyCards from "./base/CompanyCards";
import { Box, Flex, Heading } from "@chakra-ui/react";

const Experience: React.FC = () => {
  return (
    <Box id="experience">
      <Flex
        minHeight="100vh"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="2rem"
        bgGradient="linear(to top, seashell, white)"
        flexWrap="wrap"
      >
        <Heading
          fontSize={{ base: "4xl", md: "6xl" }}
          marginBottom={{ base: "50px", md: "150px" }}
        >
          Work Experience
        </Heading>
        <CompanyCards />
      </Flex>
    </Box>
  );
};

export default Experience;
