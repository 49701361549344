import airjo from "../assets/images/airjo.jpeg";
import rains from "../assets/images/Rains.jpg";
import nm from "../assets/images/nm.jpeg";
import prime from "../assets/images/prime.jpeg";
import tick from "../assets/images/tick.png";
import eames from "../assets/images/eames.jpg";
import wall from "../assets/images/wall.jpg";
import sisyphus from "../assets/images/sisyphus.jpg";
import turntable from "../assets/images/turn.jpg";

interface Item {
  imageSrc: string;
  title: string;
  link: string;
}

export const items: Item[] = [
  {
    imageSrc: airjo,
    title: "Airjo Coffee Roasters",
    link: "https://airjo.com/products/coffee-beans-thunderchild-blend?variant=9658389495855",
  },
  {
    imageSrc: rains,
    title: "Rains Backpack",
    link: "https://www.au.rains.com/collections/bags/products/trail-mountaineer-bag?variant=39591399587936",
  },
  {
    imageSrc: nm,
    title: "Nakamichi Dragon",
    link: "https://dragon.nakamichi-usa.com/",
  },
  {
    imageSrc: prime,
    title: "Optimus",
    link: "https://www.robosen.com/us/elite-optimus-prime",
  },
  {
    imageSrc: tick,
    title: "Tick Tick",
    link: "https://ticktick.com/?language=en_US",
  },
  {
    imageSrc: eames,
    title: "Eames Lounge",
    link: "https://store.hermanmiller.com/living-room-furniture-lounge-chairs-ottomans/eames-lounge-chair-and-ottoman-bamboo-based-leather-alternative/5667-2.html?lang=en_US&sku=100559402",
  },
  {
    imageSrc: wall,
    title: "The Wall",
    link: "https://www.samsung.com/us/business/displays/direct-view-led/ia-series/the-wall-all-in-one-146-p84-lh008iabmus-go/",
  },
  {
    imageSrc: sisyphus,
    title: "Sisyphus table",
    link: "#",
  },
  {
    imageSrc: turntable,
    title: "Pro-Ject Audio Turntable",
    link: "https://www.project-audio.com/en/product/2xperience-sb-sgt-pepper-limited-edition/",
  },
];
