import React from "react";
import { Flex, Heading, HStack, Image, Text, Box } from "@chakra-ui/react";
import IconWithLink from "./base/IconWithLink";
import "../styles/animations.css";
import { name } from "../constants/personalInfo";
import image from "../assets/images/SYDNEY.png";
import ScrollIndicator from "./base/ScrollIndicator";

const Home: React.FC = () => {
  return (
    <Box id="home">
      <Flex
        className="fade"
        flexDirection="column"
        justifyContent="flex-start" // Align items to the top
        alignItems="center"
        paddingX="2rem" // Horizontal padding
        paddingTop="1rem" // Adjust top padding to move content up
        paddingBottom="2rem" // Optional: adjust bottom padding
        bgGradient="linear(to top, seashell, white)"
        minHeight="100vh" // Ensure the section takes full viewport height
      >
        <Heading
          as="h2"
          size="4xl"
          color="#004e89"
          mt={20} // Adjust margin-top to move heading up or down
          mb={4} // Margin-bottom to space from the next element
        >
          {name.firstName} {name.lastName}
        </Heading>
        <Text
          fontSize="30px"
          color="#004b23"
          mb={4} // Margin-bottom to space from the next element
        >
          Senior Software Engineer
        </Text>
        <HStack spacing="20px" mb={4}>
          <IconWithLink iconType="github" />
          <IconWithLink iconType="linkedIn" />
          <IconWithLink iconType="email" />
          <IconWithLink iconType="instagram" />
        </HStack>
        <Image src={image} paddingTop="30px" />
        <ScrollIndicator />
      </Flex>
    </Box>
  );
};

export default Home;
