import React from "react";
import { Box, Image, Text, LinkBox, LinkOverlay } from "@chakra-ui/react";

interface ContentCardProps {
  imageSrc: string;
  title: string;
  link: string;
}

const ContentCard: React.FC<ContentCardProps> = ({ imageSrc, title, link }) => (
  <LinkBox
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    _hover={{ transform: "scale(1.05)" }}
    transition="transform 0.2s"
    width="100%"
  >
    <Image
      src={imageSrc}
      alt={title}
      width="100%"
      height={{ base: "200px", md: "250px" }}
      objectFit="cover"
    />

    <Box p="4">
      <LinkOverlay href={link} isExternal>
        <Text fontSize={{ base: "md", md: "lg" }} fontWeight="bold">
          {title}
        </Text>
      </LinkOverlay>
    </Box>
  </LinkBox>
);

export default ContentCard;
