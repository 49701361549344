import React from "react";
import { Link } from "react-router-dom";
import { Text, HStack, Box } from "@chakra-ui/react";
import { MotionBox, MotionHeading } from "./base/motion-chakra";
import IconWithLink from "./base/IconWithLink";
import { AllowedIcons } from "../constants/types";

const contactMethods: AllowedIcons[] = ["linkedIn", "email", "instagram"];
const Contact: React.FC = () => {
  return (
    <Box id="contact">
      <MotionBox
        bgGradient="linear(to top, white, seashell)"
        minHeight="50vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: "1" }}
      >
        <MotionHeading
          fontSize={{ base: "2xl", md: "4xl" }}
          mb={8}
          initial={{ rotate: -10 }}
          animate={{ rotate: 0 }}
          transition={{ duration: "0.5" }}
          maxWidth="500px"
          flexWrap="wrap"
        >
          <Text>{"Let's Talk."}</Text>
          <Text fontSize="2xl">
            If you like what you see, get in touch with me via email or social
            media
          </Text>
        </MotionHeading>
        <HStack spacing={{ base: 5, md: 10 }}>
          {contactMethods.map((method, key) => (
            <IconWithLink iconType={method} key={key}></IconWithLink>
          ))}
        </HStack>
        <MotionHeading
          fontSize={{ base: "2xl", md: "4xl" }}
          mb={8}
          initial={{ rotate: -10 }}
          animate={{ rotate: 0 }}
          transition={{ duration: "0.5" }}
          maxWidth="500px"
          flexWrap="wrap"
        >
          <Link to="/resume.pdf" target="_blank" download>
            <Text fontSize="md" marginTop="50px">
              Or, view my Resume
            </Text>
          </Link>
        </MotionHeading>
      </MotionBox>
    </Box>
  );
};

export default Contact;
